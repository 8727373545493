<template>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-start items-center">
      <span
        class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2"
        @click="$router.go(-1)"
      >
        <i class="fa fa-chevron-left"></i>
      </span>
      <h3>{{ $t("menu.finance_payments") }}</h3>
    </div>
    <div class="col-md-12">
      <dashboard-box v-if="item">
        <template v-slot:preview>
          <div class="row px-5 py-3">
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-12 row mx-0 font-weight-boldest"
            >
              <p class="invoice-title-font w-100">
                <strong>{{ $t("invoice.company_name") }}</strong> :
                {{
                  item.supplier_company ? item.supplier_company.name_for_accounting : ""
                }}
              </p>
              <p class="invoice-title-font w-100">
                <strong>{{ $t("general.created_at") }}</strong> :
                {{ moment(item.created_at).format("DD-MM-YYYY") }}
              </p>
              <p class="invoice-title-font w-100">
                <strong>{{ $t("invoice.created_user") }}</strong> :
                {{ item.user ? item.user.name : "" }}
              </p>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-12 row mx-0 font-weight-boldest"
            >
              <p class="invoice-title-font w-100">
                <strong>{{ $t("general.start_date") }}</strong> :
                {{ moment(item.start_date).format("DD-MM-YYYY") }}
              </p>

              <p class="invoice-title-font w-100">
                <strong>{{ $t("general.end_date") }}</strong> :
                {{ moment(item.end_date).format("DD-MM-YYYY") }}
              </p>
            </div>
          </div>
          <div class="row px-5 py-3" v-if="checkFinanceManager && item.status_id < 48">
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <custom-multi-select
                :item-per-row="1"
                :max="1"
                :model.sync="selectedApprovableInvoice"
                :name="'aprovable_invoices'"
                :not-list="true"
                :options="
                  convertArrayToObjectByKeyList(
                    aprovableInvoices,
                    'id',
                    ['invoice_no', 'total_amount', 'currency_unit.code'],
                    '-'
                  )
                "
                :required="false"
                :title="null"
                :showInvalid="false"
                size="sm"
              ></custom-multi-select>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <button-with-icon
                :icon-name="null"
                :text="$t('general.add').toUpperCase()"
                class="mx-1 font-weight-boldest"
                size="md"
                @onClick="addNewInvoice()"
              ></button-with-icon>
            </div>
          </div>
          <div class="relative">
            <div class="w-100 px-5 py-3">
              <div class="col-12">
                <textarea-input
                  :is-valid="!!item.description"
                  :model.sync="item.description"
                  :name="'finance_payment_edit_text_area'"
                  :required="true"
                  :title="$t('order.description')"
                  :disabled="item.status_id > 45"
                  :is-inline="false"
                ></textarea-input>
              </div>
              <div class="w-100 row border-15px navy-blue-border-1px mx-0 mt-5 mb-1">
                <table class="table border-15px mb-0">
                  <thead class="w-100">
                    <tr>
                      <th
                        class="w-30px navy-blue-border-right-1px navy-blue-border-bottom-1px justify-content-center items-center text-lg text-center"
                        v-if="checkFinanceManager"
                      >
                        #
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t("invoice.date") }}
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t("invoice.invoice_number") }}
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t("invoice.vat_amount") }}
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t("finance_payments.currency_total") }}
                        </div>
                      </th>
                      <th
                        class="navy-blue-border-bottom-1px"
                        :class="{
                          'navy-blue-border-right-1px':
                            checkFinanceManager &&
                            item.status_id < 48 &&
                            item.invoices.length > 1,
                        }"
                      >
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t("invoice.amount_total") }}
                        </div>
                      </th>
                      <th
                        class="navy-blue-border-bottom-1px navy-blue-border-left-1px justify-content-center items-center text-lg text-center"
                        v-if="checkFinanceManager && item.status_id > 47"
                      >
                        {{ $t("invoice.description") }}
                      </th>
                      <th
                        class="navy-blue-border-bottom-1px justify-content-center items-center text-lg text-center"
                        v-if="
                          checkFinanceManager &&
                          item.invoices.length > 1 &&
                          item.status_id < 48
                        "
                      >
                        #
                      </th>
                    </tr>
                  </thead>
                  <tbody class="navy-blue-bottom-1px" v-if="item.invoices.length">
                    <template v-for="(invoice_item, key) in item.invoices">
                      <tr class="text-right">
                        <td class="navy-blue-border-right-1px" v-if="checkFinanceManager">
                          <b-form-checkbox
                            v-model="invoice_item.edit_amount_to_be_paid"
                            :disabled="item.status_id > 47"
                            unchecked-value="null"
                            value="1"
                            size="md"
                            switch
                          >
                          </b-form-checkbox>
                        </td>
                        <td class="navy-blue-border-right-1px">
                          {{
                            moment(
                              invoice_item.invoice.invoice_date,
                              LARAVEL_DATE_TIME_FORMAT
                            ) | momentDateFormat
                          }}
                        </td>
                        <td class="navy-blue-border-right-1px">
                          {{ invoice_item.invoice.invoice_no }}
                          <span
                            v-if="checkFinanceManager"
                            @click="outOfPayment(invoice_item.invoice.id)"
                            v-html="
                              getIconByKey('icons.sample.delete_red', {
                                class:
                                  'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                              })
                            "
                          ></span>
                        </td>
                        <td class="navy-blue-border-right-1px">
                          {{ invoice_item.invoice.calculated_vat | priceFormat }}
                          {{ invoice_item.invoice.currency_unit.code }}
                        </td>
                        <td class="navy-blue-border-right-1px">
                          {{ invoice_item.invoice.amount_to_be_paid | priceFormat }}
                          {{ invoice_item.invoice.currency_unit.code }}
                        </td>
                        <td
                          :class="{
                            'navy-blue-border-right-1px':
                              checkFinanceManager &&
                              item.status_id < 48 &&
                              item.invoices.length > 1,
                          }"
                        >
                          {{
                            invoice_item.invoice.withholding_amount > 0
                              ? invoice_item.invoice.amount_to_be_paid
                              : invoice_item.invoice.amount_to_be_paid_converted
                                | priceFormat
                          }}
                          TL
                        </td>
                        <td
                          v-if="checkFinanceManager && item.status_id > 47"
                          class="navy-blue-bottom-right-1px navy-blue-border-left-1px"
                        ></td>
                        <td
                          v-if="
                            checkFinanceManager &&
                            item.invoices.length > 1 &&
                            item.status_id < 48
                          "
                        >
                          <span
                            class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2"
                            @click="deleteInvoice(invoice_item.invoice.id)"
                          >
                            <span
                              v-html="
                                getIconByKey('icons.waybill.delete_2', {
                                  class:
                                    'w-20px h-20px object-cover d-inline-block opacity-75',
                                })
                              "
                            >
                            </span>
                            {{ $t("general.delete").toUpperCase() }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="invoice_item.edit_amount_to_be_paid == 1">
                        <td></td>
                        <td class="navy-blue-border-right-1px">
                          <p v-if="item.status_id < 47">
                            {{ $t("finance_payments.please_enter_new_amounts") }}
                          </p>
                        </td>
                        <td class="navy-blue-border-right-1px"></td>
                        <td class="navy-blue-border-right-1px"></td>
                        <td class="navy-blue-border-right-1px">
                          <number-input
                            :model.sync="invoice_item.total_amount"
                            class="font-weight-bolder"
                            input-max-width="auto"
                            input-width="auto"
                            :title="$t('finance_payments.currency_total')"
                            :is-inline="false"
                            :required="false"
                            :max="invoice_item.invoice.amount_to_be_paid"
                            :disabled="item.status_id > 47"
                          ></number-input>
                        </td>
                        <td class="navy-blue-border-right-1px">
                          <number-input
                            :model.sync="invoice_item.total_amount_converted"
                            class="font-weight-bolder"
                            input-max-width="auto"
                            input-width="auto"
                            :title="$t('invoice.amount_total')"
                            :is-inline="false"
                            :required="false"
                            :max="
                              invoice_item.invoice.withholding_amount > 0
                                ? invoice_item.invoice.amount_to_be_paid
                                : invoice_item.invoice.amount_to_be_paid_converted
                            "
                            :disabled="item.status_id > 47"
                          ></number-input>
                        </td>
                        <td>
                          <textarea-input
                            :model.sync="invoice_item.description"
                            :is-inline="false"
                            :title="$t('invoice.description')"
                            name="description"
                            :disabled="item.status_id > 47"
                          ></textarea-input>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div
                class="col-12 d-flex px-0 mt-1 justify-content-end"
                v-for="(rowItem, index) in selectedRowsTotals.items"
                :key="index"
              >
                <div
                  class="w-200px py-1 text-center font-weight-bold navy-blue-border-1px border-10px"
                >
                  {{ rowItem.total | invoiceFormat }}
                  {{ rowItem.currency_unit }}
                </div>
              </div>
              <div class="col-12 d-flex px-0 mt-1 justify-content-end">
                <div
                  v-if="selectedRowsTotals.total > 0"
                  class="w-200px py-1 text-center font-weight-bold navy-blue-border-1px border-10px"
                >
                  TL {{ $t("invoice.total") }}:
                  {{ selectedRowsTotals.total | invoiceFormat }} TL
                </div>
              </div>
            </div>
          </div>
          <div
            class="row col-12 justify-content-center mt-10 pt-10"
            style="display: inline-flex; margin-top: 5% !important"
          >
            <div
              class="col-2 text-center"
              v-if="checkDeleteFinancePaymentRequest && item.status_id < 48"
            >
              <span
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2"
                @click="deleteFinancePaymentRequest"
              >
                <span
                  v-html="
                    getIconByKey('icons.waybill.delete_2', {
                      class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                    })
                  "
                >
                </span>
                {{ $t("general.delete").toUpperCase() }}
              </span>
            </div>
            <div class="col-2 text-center" v-if="item.status_id < 48">
              <span
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2"
                @click="saveRequestPaymentItem"
              >
                <span
                  v-html="
                    getIconByKey('icons.waybill.save', {
                      class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                    })
                  "
                >
                </span>
                {{ $t("general.save").toUpperCase() }}
              </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/assets/components/inputs/TextInput";
import NumberInput from "@/assets/components/inputs/NumberInput";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import DataTable from "@/assets/components/dataTable/DataTable";
import TextareaInput from "@/assets/components/inputs/TextareaInput";

import {
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING,
  RESET_VALUES,
  DELETE_ITEM_BY_ID,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/finance-payments/finance_payments.module";
import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_DATE_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";

export default {
  name: "InvoiceCreateEdit",
  components: {
    ButtonWithIcon,
    DatePickerInput,
    CustomMultiSelect,
    TextInput,
    DashboardBox,
    DataTable,
    TextareaInput,
    NumberInput,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    moment: () => moment,
    checkDescriptionEdit() {
      return this.isUserGranted("Staff", ["invoiceChief"], false);
    },
    selectedRowsTotals() {
      if (this.item.invoices === undefined) return [];
      let forCurrencyTypeList = [];
      let totalTl = 0;
      let self = this;
      let invoices = this.item.invoices;
      invoices.forEach(function (item) {
        let selectItem = item.invoice;
        if (item.edit_amount_to_be_paid != 1) {
          item.description = null;
          item.total_amount = null;
          item.total_amount_converted = null;
        }
        if (selectItem.paid_up_status >= 45 || selectItem.paid_up_status === true) {
          let findItem = forCurrencyTypeList.findIndex(
            (item) => item.currency_unit_id === selectItem.currency_unit.id
          );

          if (findItem !== -1) {
            if (selectItem.withholding_amount > 0) {
              let total =
                item.total_amount != null && item.edit_amount_to_be_paid == 1
                  ? Number(item.total_amount)
                  : Number(selectItem.amount_to_be_paid);
              forCurrencyTypeList[findItem].total += Number(total);
            } else {
              let total =
                item.total_amount != null && item.edit_amount_to_be_paid == 1
                  ? Number(item.total_amount)
                  : Number(selectItem.total_amount_including_taxes);
              forCurrencyTypeList[findItem].total += Number(total);
            }
          } else {
            let check_withholding_amount =
              selectItem.withholding_amount > 0
                ? +selectItem.amount_to_be_paid
                : +selectItem.total_amount_including_taxes;
            let total =
              item.total_amount != null && item.edit_amount_to_be_paid == 1
                ? Number(item.total_amount)
                : Number(check_withholding_amount);
            forCurrencyTypeList.push({
              currency_unit_id: +selectItem.currency_unit.id,
              currency_unit: selectItem.currency_unit.code,
              total: total,
            });
          }
          self.showSlectedInvoiceRowTotal = true;
          if (
            !(selectItem.currency_unit_id == 160 || selectItem.currency_unit_id == 53)
          ) {
            let check_withholding_amount =
              selectItem.withholding_amount > 0
                ? Number(selectItem.amount_to_be_paid)
                : Number(selectItem.total_amount_including_taxes_converted);
            totalTl +=
              item.total_amount_converted != null && item.edit_amount_to_be_paid == 1
                ? Number(item.total_amount_converted)
                : Number(check_withholding_amount);
          }
        }
      });
      this.forCurrencyTypeList = _.orderBy(this.forCurrencyTypeList, "total", "desc");
      let data = {
        items: forCurrencyTypeList,
        total: totalTl,
      };
      return data;
    },
    isValidToSubmit() {
      let result = true;
      if (this.isUserGranted("Staff", ["financeManager"], false)) {
        this.item.invoices.forEach((inviceItem) => {
          if (
            inviceItem.edit_amount_to_be_paid == 1 ||
            inviceItem.edit_amount_to_be_paid == 1
          ) {
            if (
              inviceItem.total_amount == null ||
              inviceItem.total_amount_converted == null ||
              inviceItem.description == null
            ) {
              result = false;
            }
          }
        });
      }
      return result;
    },
    checkFinanceManager() {
      return this.isUserGranted("Staff", ["financeManager"], false);
    },
    checkDeleteFinancePaymentRequest() {
      return this.isUserGranted("InvoicePaymentRequest", ["delete"], false);
    },
    approvableInvoicesOptions() {
      let data = this.aprovableInvoices;
      return this.convertArrayToObjectByKey(data, "id", "code");
    },
  },
  data() {
    return {
      itemId: this.$route.params.id,
      item: null,
      defaultCurrency: "TL",
      item: { data: [] },
      invoiceManagementDescription: null,
      invoiceItems: [],
      aprovableInvoices: [],
      aprovableInvoicesOptions: [],
      selectedApprovableInvoice: null,
    };
  },
  methods: {
    ...mapMutations({}),
    outOfPayment(id, index) {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/invoices/invoice-payment-request/out-of-payment/" + id,
          acceptPromise: true,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            self.sweetAlertSuccess(self.$t("general.successfully_created")).then(() => {
              let index = this.item.invoices.findIndex(
                (invoice) => invoice.invoice.id == id
              );
              this.item.invoices.splice(index, 1);
            });
          } else {
            self.sweetAlertError(self.$t("general.internal_error"));
          }
        });
    },
    deleteFinancePaymentRequest() {
      let self = this;
      let url = `api/invoices/invoice-payment-request/${self.itemId}`;
      this.$store.dispatch(DELETE_ITEM_BY_ID, { url: url }).then((result) => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t("general.successfully_deleted"));
          this.$route.go(-1);
        } else {
          let response = result.data.response;
          if (
            response.hasOwnProperty("data") &&
            response.data.hasOwnProperty("message")
          ) {
            self.sweetAlertError(response.data.message);
          } else {
            self.sweetAlertError(response.data);
          }
        }
      });
    },
    addNewInvoice() {
      if (this.selectedApprovableInvoice == null) {
        this.sweetAlertError(this.$t("finance_payments.please_selected_invoice_number"));
        return false;
      }
      let id = this.selectedApprovableInvoice;
      let index = this.aprovableInvoices.findIndex((invoice) => invoice.id == id);
      this.item.invoices.push({
        invoice: this.aprovableInvoices[index],
      });
      this.aprovableInvoices.splice(index, 1);
      this.selectedApprovableInvoice = null;
    },
    saveRequestPaymentItem() {
      if (!this.isUserGranted("InvoicePaymentRequest", ["update"])) {
        return false;
      }

      if (this.item.description == null || !this.item.description) {
        this.sweetAlertError(this.$t("finance_payments.error_description"));
        return false;
      }
      let sendControl = false;
      this.item.invoices.forEach((invoice, key) => {
        if (invoice.edit_amount_to_be_paid == 1) {
          if (invoice.total_amount == null) {
            this.sweetAlertError(
              invoice.invoice.invoice_no +
                " " +
                this.$t("finance_payments.not_empty_currency_val")
            );
            sendControl = true;
          }
          if (invoice.total_amount_converted == null) {
            this.sweetAlertError(
              invoice.invoice.invoice_no +
                " " +
                this.$t("finance_payments.not_empty_tl_val")
            );
            sendControl = true;
          }
        }
      });
      if (sendControl) {
        return false;
      }
      let self = this;
      let payload = {
        url: `api/invoices/invoice-payment-request/${self.itemId}`,
        contents: this.item,
      };

      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then((result) => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t("general.successfully_saved")).then(() => {
            self.$router.push({ name: "finance_payments.index" });
          });
          if (!(result.data && result.data.data && result.data.data.length)) {
            $state.complete();
          }
          if ($state) {
            $state.loaded();
          }
        } else {
          self.sweetAlertError(self.$t("general.internal_error"));
        }
      });
    },
    deleteInvoice(id) {
      this.sweetAlertConfirm("general.are_you_sure").then((result) => {
        if (result) {
          let index = this.item.invoices.findIndex((invoice) => invoice.invoice.id == id);
          this.aprovableInvoices.push(this.item.invoices[index].invoice);
          this.item.invoices.splice(index, 1);
        }
      });
    },
  },
  created() {
    this.$store.commit(RESET_VALUES);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.finance_payments"), route: "invoice.index" },
      { title: this.$t("finance_payments.edit") },
    ]);

    if (!this.isUserGranted("InvoicePaymentRequest", ["view"])) {
      return false;
    }
    let self = this;
    let url = `api/invoices/invoice-payment-request/${self.itemId}`;
    this.$store.dispatch(GET_ITEMS, { url: url }).then((result) => {
      if (result.status) {
        let data = result.data.data;
        self.item = data;

        Object.entries(self.item.invoices).forEach(([key, val]) => {
          if (val.total_amount != null) {
            self.item.invoices[key].edit_amount_to_be_paid = 1;
          }
        });

        if (result.data.aprovable_invoices && result.data.aprovable_invoices != null) {
          this.aprovableInvoices = result.data.aprovable_invoices;
        }
        if (!(result.data && result.data.data && result.data.data.length)) {
          $state.complete();
        }
        if ($state) {
          $state.loaded();
        }
      } else {
        if ($state) {
          $state.complete();
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.h-50vh {
  height: 50vh !important;
}

.w-40 {
  width: 40% !important;
}
.invoice-title-font {
  font-weight: 400;
}
</style>
